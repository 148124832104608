<template>
  <div class="register left-float-top ml-30">


  </div>
</template>

<script>
import {  getOrganDetail, } from '@/api/index'
export default {
  name: "Index",
  data() {
    return {
      organ:{},
      organId:'',
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
      this.reqOrganDetail()
    }

  },
  methods: {
    // typeof self.organ.authProfile !== "undefined"
    // 获取机构信息
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(this.organId)
      self.organ = res.biz
      if ( null != self.organ.authProfile && undefined != self.organ.authProfile) {

        self.openMateral();
      }else {
        this.$confirm('抱歉，您当前在'+self.organ.certificationStatusName+'状态，无法进行上传建设材料相关动作！请先前往发起认证，进行发起认证申请操作。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        }).catch(() => {
        });
      }
    },
    openMateral:function (){
      this.$router.push('/auth/material')

    },
  },
}
</script>

<style lang="scss">
.certificate-collapse {
  width: 100%;

  .el-collapse-item__header {
    background: #508caa;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
  }

  .certificate-collapse-li {
    background: #d5f6d8;
    border-bottom: 3px solid #fff;
    line-height: 28px;
    padding: 8px 10px;
    word-wrap: break-word;
    font-size: 14px;

    & > div {
      font-weight: bold;
    }
  }

  .certificate-collapse-btn {
    width: 120px;
    text-align: center;
  }
}

.el-divider__text {
  background-color: #d5f6d8;
  font-size: 25px;
}

.line {
  width: 100%;
  border: 1px solid #DDDFE6
}

.text-title {
  color: #000;
  font-weight: bold
}
</style>